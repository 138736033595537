var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"border-0 card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('message.favorites'))+" ")])]),_c('div',{staticClass:"col-6 text-right"})])]),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","empty-text":_vm.$t('message.noFavorites'),"height":"200","data":_vm.userProjectsWithoutArchived},on:{"sort-change":_vm.sortByDate}},[_c('el-table-column',{attrs:{"prop":"fav","width":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.currentUserRights.includes('project.favourite.cansee'))?_c('a',{staticClass:"clickable",on:{"click":function($event){return _vm.toggleFavProject(row.id)}}},[_c('i',{staticClass:"fa fa-star",class:[_vm.isFavorite(row) ? 'active-star' : 'inactive-star']})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.project'),"min-width":"220","prop":"name","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":("/project/" + (row.id))}},[_vm._v(_vm._s(row.name))]),(_vm.unreadProjectNotifications(row.id).length > 0 && _vm.currentUserRights.includes('Dashboard Projekt A_Benachrichtigungsfeld'))?_c('el-badge',{staticClass:"project-notification-item",attrs:{"value":_vm.unreadProjectNotifications(row.id).length}},[_c('i',{staticClass:"fas fa-bell faa-shake animated"})]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.projectId'),"prop":"key","width":"140","sortable":"custom"}}),_c('el-table-column',{attrs:{"label":_vm.$t('message.contactPerson'),"prop":"ansprech","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"avatar-group"},_vm._l((_vm.getProjectPersons(row.projectPersons)),function(user){return _c('avatar',{key:user.id,attrs:{"user-id":user.user_id}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.eventDate-from'),"prop":"from","width":"220","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(row.from)))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.eventDate-to'),"prop":"to","width":"220","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(row.to)))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('milestone.deadline-druck'),"width":"130","sortable":"","prop":"dealineDate"}}),(_vm.currentUserRights.includes('dashboard.ladetermin.isvisible'))?_c('el-table-column',{attrs:{"label":_vm.$t('milestone.lade-termin'),"prop":"ladetermin","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getLoadingDate(row)))])]}}],null,false,14140047)}):_vm._e(),(_vm.currentUserRights.includes('component.project-table.status'))?_c('el-table-column',{attrs:{"label":_vm.$t('milestone.state'),"prop":"status","width":"160","sortable":"","fixed":"right","aligh":"center","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('project-status-stacked-bar',{attrs:{"progress":row.progress,"project":row}})]}}],null,false,703445168)}):_vm._e(),_c('el-table-column',{attrs:{"align":"right","width":"150","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('base-button',{attrs:{"size":"sm","type":"primary"},on:{"click":function($event){return _vm.$router.push({ path: ("/project/" + (row.id)) })}}},[_vm._v(" "+_vm._s(_vm.$t('message.goToProject'))+" ")])]}}])})],1),((_vm.projectDeadlineTomorrow.length > 0 && !_vm.tomorrowDeadlinesSeen) || (_vm.projectDeadlineNextWeek.length > 0 && !_vm.nextWeekDeadlinesSeen))?_c('modal',{attrs:{"show":_vm.showDeadlineModal},on:{"update:show":function($event){return _vm.dismissDeadlineModal()}}},[_c('h6',{staticClass:"modal-title",attrs:{"slot":"header"},slot:"header"},[_vm._v("REMINDER")]),(_vm.projectDeadlineTomorrow.length > 0 && !_vm.tomorrowDeadlinesSeen)?_c('div',[_c('h4',[_vm._v("Morgen fällig:")]),_vm._l((_vm.projectDeadlineTomorrow),function(deadline){return _c('div',{key:deadline,staticClass:"mb-3"},[_vm._v("Die Deadline für das Projekt "+_vm._s(deadline.key)+" \""+_vm._s(deadline.name)+"\" läuft morgen ab.")])})],2):_vm._e(),(_vm.projectDeadlineNextWeek.length > 0 && !_vm.nextWeekDeadlinesSeen)?_c('div',[_c('h4',[_vm._v("Nächste Woche fällig:")]),_vm._l((_vm.projectDeadlineNextWeek),function(deadlineNW){return _c('div',{key:deadlineNW,staticClass:"mb-3"},[_vm._v("Die Deadline für das Projekt "+_vm._s(deadlineNW.key)+" \""+_vm._s(deadlineNW.name)+"\" läuft in einer Woche ab.")])})],2):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }