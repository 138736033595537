import Vue from 'vue'
import i18n from '../../../i18n'
// mport api from 'api-client'

const state = {
  locales: [
    'de',
    'en'
  ],
  currentLocale: 'de',
  searchCtx: {},
  bhbViewColumns: 0,
  bhbFilterType: null,
  ladeFilterType: null,
  kommFilterType: null,
  montageFilterType: null,
  ruecklagerungFilterType: null,
  bhbFilterValue: 0,
  IMStatusFilterValue: 0,
  kommFilterValue: 0,
  ladeFilterValue: 0,
  montageFilterValue: 0,
  ruecklagerungFilterValue: 0,
  internalPersonTopics: [
    {
      id: 1,
      val: "SPL",
      name: "Senior Projektleiter"
    },
    {
      id: 2,
      val: "PL",
      name: "Projektleiter"

    },
    {
      id: 3,
      val: "DESIGNER",
      name: "Grafiker"

    },
    {
      id: 4,
      val: "KOORDINAT",
      name: "Techniker-Koordinator"
    },
    {
      id: 5,
      val: "TECHNIKER",
      name: "Techniker"
    },

    {
      id: 6,
      val: "BEREICHSL",
      name: "Bereichsleiter"
    },
    {
      id: 7,
      val: "PROJEKTB",
      name: "Projektbetreuer"
    },
    {
      id: 8,
      val: "GF",
      name: "Geschäftsführer"
    },
  ],
  externalPersonTopics: [
    {
      id: 1,
      val: 'DRUCK',
      name: "Druckdaten"
    },
    {
      id: 2,
      val: 'GESAMT',
      name: "Gesamtkoordination"
    },
    {
      id: 3,
      val: 'SICHERHEIT',
      name: "Sicherheit"
    },
    {
      id: 4,
      val: 'HOSP',
      name: "Hospitality"
    },
    {
      id: 5,
      val: 'MEDIEN',
      name: "Medien"
    },
    {
      id: 6,
      val: 'TICKETS',
      name: "Ticketing"
    },
    {
      id: 7,
      val: 'STADION',
      name: "Stadionbetreiber"
    },
    {
      id: 8,
      val: 'EXPL',
      name: "externer Projektleiter"
    }
  ],
  // Stati BHB
  bhbStateValues: [
    'LEER',
    'OFFEN',
    'KORREKTUR',
    'FREIGEGEBEN',
    'IN_PRODUKTION',
  ],
  // Stati Kommissionierungsliste
  kommStateValues: [
    'OFFEN',
    'BEMERKUNG',
    'KOMMISSIONIERT'
  ],
  // Stati Konfektionierung
  konfStateValues: [
    null,
    'yellow',
    'green'
  ],
  // Stati Ladeliste
  ladeStateValues: [
    'OFFEN',
    'BEMERKUNG',
    'GELADEN'
  ],
  // Stati Montageliste
  montageStateValues: [
    'OFFEN',
    'MONTIERT'
  ],
  // Stati Rücklagerungsliste
  ruecklagerungStateValues: [
    'OFFEN',
    'BEMERKUNG',
    'ZURUECK'
  ],
  standardColors: [
    'GELB',
    'ROT',
    'BLAU',
    'GRÜN',
    'ORANGE',
    'LILA',
    'HELLGRÜN',
    'HELLBLAU',
    'PINK',
    'GRAU',
    'SCHWARZ'
  ],
  sidebarIsPinned: false,
  sidebarIsVisible: false,
  ColorFilterValue: '$',
  IMAreaFilterValue: '$',
  IMPositionFilterValue: '$',
  IMMountedFilterValue: '$',
  IMOrderFilterValue: '$',
  IMEconomicRelease: true,
  IMNoEconomicRelease: true,
  IMActionPlFilter: false,
  IMActionNoPlFilter: false,
  IMShowRejected: false,
  pickingListCurrentPage: 1
};

const getters = {
  bhbFilterType: state => state.bhbFilterType,
  ladeFilterType: state => state.ladeFilterType,
  kommFilterType: state => state.kommFilterType,
  montageFilterType: state => state.montageFilterType,
  ruecklagerungFilterType: state => state.ruecklagerungFilterType,
  bhbFilterValue: state => state.bhbFilterValue,
  kommFilterValue: state => state.kommFilterValue,
  ladeFilterValue: state => state.ladeFilterValue,
  montageFilterValue: state => state.montageFilterValue,
  ruecklagerungFilterValue: state => state.ruecklagerungFilterValue,
  bhbStateValues: state => state.bhbStateValues,
  authorisedStateValues: (state, { currentUserRights }) => {
    return state.bhbStateValues
      .filter(r => {
        if (r === 'FREIGEGEBEN' && !currentUserRights.includes('notification.status-bhb.freigabe')) {
          return false
        }
    
        if (r === 'KORREKTUR' && !currentUserRights.includes('notification.status-bhb.korrektur')) {
          return false
        }
        
        if (r === 'LEER' && !currentUserRights.includes('notification.status-bhb.leer')) {
          return false
        }
    
        if (r === 'IN_PRODUKTION' && !currentUserRights.includes('notification.status-bhb.in-produktion')) {
          return false
        }
        
        if (r === 'OFFEN' && !currentUserRights.includes('notification.status-bhb.offen')) {
          return false
        }
        
        if (r === 'ABGELEHNT' && !currentUserRights.includes('notification.status-bhb.ablehnen')) {
          return false
        }
        return true
      })
  },
  kommStateValues: state => state.kommStateValues,
  konfStateValues: state => state.konfStateValues,
  ladeStateValues: state => state.ladeStateValues,
  montageStateValues: state => state.montageStateValues,
  ruecklagerungStateValues: state => state.ruecklagerungStateValues,
  bhbViewColumns: state => state.bhbViewColumns,
  currentLocale: state => state.currentLocale,
  sidebarIsPinned: state => state.sidebarIsPinned,
  sidebarIsVisible: state => state.sidebarIsVisible,
  userRoles: state => state.userRoles,
  locales: state => state.locales,
  externalPersonTopics: state => state.externalPersonTopics,
  internalPersonTopics: state => state.internalPersonTopics,
  searchCtx: state => state.searchCtx,
  ColorFilterValue: state =>  state.ColorFilterValue,
  IMStatusFilterValue: state => state.IMStatusFilterValue,
  IMAreaFilterValue: state =>  state.IMAreaFilterValue,
  IMPositionFilterValue: state =>  state.IMPositionFilterValue,
  IMMountedFilterValue: state =>  state.IMMountedFilterValue,
  IMEconomicRelease: state =>  state.IMEconomicRelease,
  IMNoEconomicRelease: state =>  state.IMNoEconomicRelease,
  IMActionPlFilter: state => state.IMActionPlFilter,
  IMActionNoPlFilter: state => state.IMActionNoPlFilter,
  IMOrderFilterValue: state => state.IMOrderFilterValue,
  IMShowRejected: state => state.IMShowRejected,
  pickingListCurrentPage: state => state.pickingListCurrentPage,
  standardColors: state => state.standardColors
}

const mutations = {
  setBhbViewColumns(state, columns) {
    state.bhbViewColumns = columns;
  },
  setSidebarPinned(state, value) {
    state.sidebarIsPinned = !!value;
  },
  setSidebarVisible(state, value) {
    state.sidebarIsVisible = !!value;
  },
  bhbFilterType(state, type) {
    state.bhbFilterType = type;
  },
  bhbFilterValue(state, val) {
    state.bhbFilterValue = val;
  },
  kommFilterType(state, type) {
    state.kommFilterType = type;
  },
  kommFilterValue(state, val) {
    state.kommFilterValue = val;
  },
  ladeFilterValue(state, val) {
    state.ladeFilterValue = val;
  },
  ruecklagerungFilterValue(state, val) {
    state.ruecklagerungFilterValue = val;
  },
  newOrderCount(state, val) {
    state.newOrderCount = val;
  },
  startTime(state, val) {
    state.startTime = val;
  },
  adminOrderhistoryFilters(state, val) {
    state.adminOrderhistoryFilters = val;
  },
  eventBaseGroups(state, eventBaseGroups) {
    state.eventBaseGroups = eventBaseGroups;
  },
  currentLocale(state, locale) {
    state.currentLocale = locale;
    i18n.locale = locale
  },
  searchCtx(state, ctx) {
    state.searchCtx = ctx;
  },
  ColorFilterValue (state, value) {
    state.ColorFilterValue = value
  },
  IMStatusFilterValue (state, value) {
    state.IMStatusFilterValue = value
  },
  IMAreaFilterValue (state, value) {
    state.IMAreaFilterValue = value
  },
  IMPositionFilterValue (state, value) {
    state.IMPositionFilterValue = value
  },
  IMMountedFilterValue (state, value) {
    state.IMMountedFilterValue = value
  },
  IMEconomicRelease (state, value) {
    state.IMEconomicRelease = value
  },
  IMNoEconomicRelease (state, value) {
    state.IMNoEconomicRelease = value
  },
  IMActionPlFilter (state, value) {
    state.IMActionPlFilter = value
  },
  IMActionNoPlFilter (state, value) {
    state.IMActionNoPlFilter = value
  },
  IMOrderFilterValue (state, value) {
    state.IMOrderFilterValue = value
  },
  IMShowRejected (state, value) {
    state.IMShowRejected = value
  },
  SET_PICKING_LIST_CURRENT_PAGE (state, value) {
    state.pickingListCurrentPage = value
  }
};

const actions = {
  setBhbViewColumns({ commit }, columns) {
    commit('setBhbViewColumns', columns);
  },
  setSidebarPinned({ commit }, value = !state.sidebarIsPinned) {
    commit('setSidebarPinned', value);
  },
  setSidebarVisible({ commit }, value = !state.sidebarIsVisible) {
    commit('setSidebarVisible', value);
  },
  clearFilter({ commit }) {
    commit('bhbFilterType', null)
    commit('bhbFilterValue', 0)
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
