<template>
    <div class="card">
        <div class="border-0 card-header">
             <div class="row">
                <div class="col-6">
                    <h3 class="mb-0">{{$t('message.favorites')}}
                    </h3>
                </div>
                <div class="col-6 text-right">
                    <!-- <base-split-button type="primary" size="sm" text="Neues Projekt" @primary-click="$router.push({ path: '/admin/projects/add'})">
                        <a class="dropdown-item">Neu aus Vorlage</a>
                    </base-split-button> -->
                </div>
             </div>
        </div>
        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :empty-text="$t('message.noFavorites')"
                  height="200"
                  :data="userProjectsWithoutArchived"
                  @sort-change="sortByDate">
            <el-table-column
                prop="fav"
                width="30">
                <template v-slot="{row}">
                    <a class="clickable" @click="toggleFavProject(row.id)" v-if="currentUserRights.includes('project.favourite.cansee')">
                        <i class="fa fa-star" v-bind:class="[isFavorite(row) ? 'active-star' : 'inactive-star']"></i>
                    </a>
                </template>
            </el-table-column>
            <el-table-column
                :label="$t('message.project')"
                min-width="220"
                prop="name"
                sortable="custom">
                <template v-slot="{row}">
                    <div class="d-flex">
                        <router-link :to="`/project/${row.id}`">{{row.name}}</router-link>
                        <el-badge :value="unreadProjectNotifications(row.id).length" class="project-notification-item" v-if="unreadProjectNotifications(row.id).length > 0 && currentUserRights.includes('Dashboard Projekt A_Benachrichtigungsfeld')">
                            <i class="fas fa-bell faa-shake animated"></i>
                        </el-badge>
                    </div>
                </template>
            </el-table-column>
             <el-table-column :label="$t('message.projectId')"
                             prop="key"
                             width="140"
                             sortable="custom">

            </el-table-column>
            <el-table-column :label="$t('message.contactPerson')"
                             prop="ansprech"
                             width="250">
                <template v-slot="{row}">
                    <div class="avatar-group">
                        <avatar v-for="user in getProjectPersons(row.projectPersons)" :user-id="user.user_id" :key="user.id"></avatar>
                    </div>
                </template>
            </el-table-column>
             <!-- <el-table-column :label="$t('milestone.veranstaltungszeitraum')"
                prop="earliest"
                width="220"
                sortable>
                <template v-slot="{row}">
                    <span>{{getProjectPeriod(row)}}</span>
                </template>
            </el-table-column> -->
             <el-table-column :label="$t('message.eventDate-from')"
                prop="from"
                width="220"
                sortable="custom">
                <template v-slot="{row}">
                    <span>{{ row.from | date }}</span>
                </template>
            </el-table-column>
               <el-table-column :label="$t('message.eventDate-to')"
                prop="to"
                width="220"
                sortable="custom"
                >
                <template v-slot="{row}">
                    <span>{{ row.to | date }}</span>
                </template>
            </el-table-column>
            <el-table-column
                :label="$t('milestone.deadline-druck')"
                width="130"
                sortable
                prop="dealineDate">
                <!-- <template v-slot="{row}">
                    <span>{{getMilestoneByPhase(row, 'DRUCK')}}</span>
                </template> -->
            </el-table-column>
            <el-table-column :label="$t('milestone.lade-termin')"
                            prop="ladetermin"
                            width="130"
                            v-if="currentUserRights.includes('dashboard.ladetermin.isvisible')"
                            >
                <template v-slot="{row}">
                    <span>{{getLoadingDate(row)}}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="Kunde"
                             prop="customer"
                             sortable>
            </el-table-column> -->

            <el-table-column :label="$t('milestone.state')"
                             prop="status"
                             width="160"
                             sortable
                             fixed="right"
                             aligh="center"
                             header-align="center"
                             v-if="currentUserRights.includes('component.project-table.status')">
                <template v-slot="{row}">
                    <project-status-stacked-bar :progress="row.progress" :project="row"></project-status-stacked-bar>
                </template>
            </el-table-column>
            <el-table-column align="right" width="150" fixed="right">
                <template v-slot="{row}">
                   <base-button size="sm" type="primary" @click="$router.push({ path: `/project/${row.id}` })">
                        {{$t('message.goToProject')}}
                    </base-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- <div class="card-footer py-4 d-flex justify-content-end">
            <project-status-bar-legend></project-status-bar-legend>
        </div> -->

        <modal v-if="(projectDeadlineTomorrow.length > 0 && !tomorrowDeadlinesSeen) || (projectDeadlineNextWeek.length > 0 && !nextWeekDeadlinesSeen)" :show="showDeadlineModal" @update:show="dismissDeadlineModal()">
            <h6 slot="header" class="modal-title">REMINDER</h6>
            <div v-if="projectDeadlineTomorrow.length > 0 && !tomorrowDeadlinesSeen">
                <h4>Morgen fällig:</h4>
                <div v-for="deadline in projectDeadlineTomorrow" :key="deadline" class="mb-3">Die Deadline für das Projekt {{ deadline.key }} "{{ deadline.name }}" läuft morgen ab.</div>
            </div>
            <div v-if="projectDeadlineNextWeek.length > 0 && !nextWeekDeadlinesSeen">
                <h4>Nächste Woche fällig:</h4>
                <div v-for="deadlineNW in projectDeadlineNextWeek" :key="deadlineNW" class="mb-3">Die Deadline für das Projekt {{ deadlineNW.key }} "{{ deadlineNW.name }}" läuft in einer Woche ab.</div>
            </div>
        </modal>
    </div>
</template>
<script>
    import ProjectStatusStackedBar from '@/components/ProjectStatusStackedBar.vue'
    import ProjectStatusBarLegend from '@/components/ProjectStatusBarLegend.vue'
    import { mapGetters, mapActions } from 'vuex'
    import moment from 'moment'
    import { formatDate } from '@/util/data'


    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const nextWeek = new Date(today);
    nextWeek.setDate(nextWeek.getDate() + 7);

  export default {
    name: 'project-table',
    components: {
        ProjectStatusStackedBar,
        ProjectStatusBarLegend
    },
    computed: {
        ...mapGetters([
            'currentUser',
            'projects',
            'userProjects',
            'internalPersonTopics',
            'currentUserRights',
            'unreadProjectNotifications'
        ]),
        favColWidth () {
            const val = (!this.currentUserRights.includes('project.favourite.cansee') ? '0' : '70')
            return val
        },
        userProjectsWithoutArchived() {

          this.projectDeadlineTomorrow = [];
          this.projectDeadlineNextWeek = [];

          const self = this
          return this.userProjects
            // .filter(project => {
            //     if (project.is_private) {
            //         return self.currentUserRights.includes('project.can-see.private')
            //     }
            //     return true
            // })
            .filter(project => project.state !== 'ARCHIVED')
            .map(project => {
                const fromDates = (project.projectAddresses || [])
                    .filter(pa => pa.date_from !== null)
                    .map(pa => pa.date_from);
                const toDates = (project.projectAddresses || [])
                    .filter(pa => pa.date_to !== null)
                    .map(pa => pa.date_to);
                const { length,  0: from, [length - 1]: to } = [...fromDates, ...toDates]
                    .sort((a, b) => new Date(a) - new Date(b))
                    .map(d => new Date(d));

                if (project.projectAddresses) {
                     const deadlineDates = project.projectAddresses
                        .filter(pa => pa.deadline_print !== null)
                        .map(pa => formatDate(pa.deadline_print))
                        .sort((a, b) => new Date(a) - new Date(b))

                    if (deadlineDates.length > 0) {
                        project.dealineDate = deadlineDates[0]

                        if (project.dealineDate == formatDate(tomorrow)) {
                            this.projectDeadlineTomorrow.push(project);
                        }
                        if (deadlineDates.includes(formatDate(nextWeek))) {
                            this.projectDeadlineNextWeek.push(project);
                        }
                    }
                }

                if (!from || !to) {
                    const date = new Date()
                    date.setFullYear(new Date().getFullYear() - 1000)
                    project.earliest = date // new Date(from)
                }
                
                project.from = from
                project.to = to
            
                const milestone = project.milestones ? project.milestones.find(m => m.phase === 'DRUCK') : null
                if (milestone) {
                    project.druck = new Date(milestone.due)
                } 
                return project
            })
            .sort((rowA, rowB) => {
                if (!this.sortBy) {
                    return 0
                }
                
                if (['from', 'to', 'dealineDate', 'ladetermin'].includes(self.sortBy)) {
                    const a = moment(rowA[self.sortBy],'DD.MM.YYYY')
                    const b = moment(rowB[self.sortBy],'DD.MM.YYYY')
                    if (!a.isValid()) {
                        return 1
                    }

                    if (!b.isValid()) {
                        return -1
                    }
                    const res = self.sortOrder * ((+a) - (+b))

                    return res
                }
        
                return self.sortOrder * rowA[self.sortBy].localeCompare(rowB[self.sortBy])
            })
        }
    },
    data() {
      return {
        currentPage: 1,
        favs: [],
        sortBy: 'from',
        sortOrder: 1,
        showDeadlineModal: true,
        projectDeadlineTomorrow: null,
        projectDeadlineNextWeek: null,
        tomorrowDeadlinesSeen: null,
        nextWeekDeadlinesSeen: null
      }
    },
    watch: {
      currentUser(val) {
          this.updateFavs()
      }
    },
    methods: {
        ...mapActions([
            'toggleFavProject',
            'loadPersonsForProject'
        ]),
        getProjectPersons (persons) {
            const topicVals = this.internalPersonTopics.map(t => t.val)
            return persons ? persons.filter(p => topicVals.includes(p.topic)) : []
        },
        getProjectPeriod(project) {
            const fromDates = (project.projectAddresses || [])
                .filter(pa => pa.date_from !== null)
                .map(pa => pa.date_from);
            const toDates = (project.projectAddresses || [])
                .filter(pa => pa.date_to !== null)
                .map(pa => pa.date_to);
            const { length,  0: from, [length - 1]: to } = [...fromDates, ...toDates]
                .sort((a, b) => {
                    return new Date(a) - new Date(b)
                })
                .map(d => formatDate(new Date(d)));
            if (!from || !to) {
                return ''
            }
        
            return `${from} - ${to}`
        },
        getMilestoneByPhase (row, phase) {
            if (row.milestones) {
                
                const milestone = row.milestones.find(m => m.phase === phase)

                if (!milestone) {
                    return null
                }

                const date = new Date(milestone.due)

                // todo: Muss abhängig von Benutzersprache sein.
                // DS: laut matthias ist einheitliche darstellung DD.MM.YYYY gewünscht.
                return formatDate(date);
            }
            return null

        },
        getLoadingDate(project) {
            if (!project.rides) {
                return
            }

            const loadingDates = project.rides
                .filter(pa => pa.load !== null)
                .map(pa => new Date(pa.load))
                .sort((a, b) => {
                    return a - b
                })

            return formatDate(loadingDates[0])
        },
        isFavorite(project) {
            return this.favs.indexOf(project.id) != -1;
        },
        updateFavs() {
          const ids = this.currentUser && this.currentUser.fav_projects ? this.currentUser.fav_projects.split(',') : []
          this.favs = ids.map(id => parseInt(id))
        },
        sortByDate (e) {
            if (e.order === null) {
                this.sortBy = 'from'
                this.sortOrder = 1
                return
            }

            this.sortBy = e.prop
            this.sortOrder = e.order === 'ascending' ? 1 : -1
        },
        dismissDeadlineModal() {
            this.showDeadlineModal = false;
            localStorage.setItem("APA_SHOW_DEADLINES_TOMORROW", formatDate(today));
            localStorage.setItem("APA_SHOW_DEADLINES_NEXT_WEEK", formatDate(nextWeek));
        }
    },
    mounted () {
      this.updateFavs()
      this.tomorrowDeadlinesSeen = localStorage.getItem("APA_SHOW_DEADLINES_TOMORROW") == formatDate(today);
      this.nextWeekDeadlinesSeen = localStorage.getItem("APA_SHOW_DEADLINES_NEXT_WEEK") == formatDate(nextWeek);
    }
  }
</script>

<style>
@media (max-width: 1023px) {
    .el-table__fixed-right {
        display: none !important;
    }
}
</style>
